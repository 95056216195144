import uri from 'utils/uri';
import { Environment } from 'utils/environment';

import type { FlagSettings } from './contracts';

enum Flags {
  /**
   * BE (X-Feature-Toggles header) flags
   */
  disableStoryAutoApprovalForTestAccounts = 'disableStoryAutoApprovalForTestAccounts',
  disableEmailAutoconfirmation = 'disableEmailAutoconfirmation',
  forceShowNewMaleCategories = 'forceShowNewMaleCategories',
  forceGeoIP = 'forceGeoIP',
  /**
   * FE flags
   */
  enableDataTestID = 'enableDataTestID',
  forceAu10TixIdVerification = 'forceAu10TixIdVerification',
  enableQAMode = 'enableQAMode',
  enableUnknownCookieCollection = 'enableUnknownCookieCollection',
  enableHindi = 'enableHindi',
  payoutOverviewRedesign = 'payoutOverviewRedesign',
  includeAwardsTestAccounts = 'includeAwardsTestAccounts',
  enableMusicLibrary = 'enableMusicLibrary',
}

const settings: Partial<Record<Flags, FlagSettings>> = {
  [Flags.forceGeoIP]: {
    lifetime: 'session',
    envBlacklist: [Environment.Production, Environment.Staging],
    parser(value) {
      if (!uri.ip(value ?? '')) return undefined;

      return value;
    },
    enabled(value) {
      return uri.ip(String(value));
    },
  },
};

export { Flags, settings };
